@font-face {
  font-family: turtle;
  src: url(./Turtles.otf);
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: antiquewhite;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 5%;
  padding-top:2rem;
  border-radius: .5rem;
  width: 40rem;
}

.turtles {
  width: 16rem;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 6rem;
}

.turtles.small {
  width: 8rem;
}

.form {
  display: flex;
  flex-direction: column;
}

.form label {
  color: red
}


h4, h2 {
  margin: .5rem;
}

.pizzas {
  display: flex;
  flex-direction: row;
  width: 20rem;
  justify-content: space-around;
  flex-wrap: wrap;
}

.pizzas h2 {
  margin: .5rem;
}

.pizzas h5:hover {
  cursor: pointer;
  color: red;
} 

.size {
  display: flex;
  flex-direction: row;
  width: 30rem;
  justify-content: space-around;
  flex-wrap: wrap;
}

.crust {
  display: flex;
  flex-direction: row;
  width: 30rem;
  justify-content: space-around;
  flex-wrap: wrap;
}

.option-group {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-content: space-around;
  width: 30rem;
}

.option-group :hover {
  cursor: pointer;
  color: red;
}

.selected {
  color: red;
}

body {
  font-family: Turtle;
  font-size: 1rem;
  background-color: green;
  color: black;
}

h1, h2, h3, h4, h5, h6 {
  font-family: turtle;
  font-weight: 300;
  color: #424242;
  margin: 0 0 10px;
  font-weight: 600;
  color: black;
  font-weight: 200;
  text-align: center;
}

button {
  color: black;
  /* background-color: green; */
  font-family: turtle;
  margin: .5rem;
  font-weight: 10;
  background-color: green;
  color: black;
  border: none;
}

button:hover {
  color: black;
}

.sign-in-form input {
  margin: .5rem;
  border-color: black;
}

.sign-in-form button {
  width: 66%;
  margin-left: 18%;
  margin-right: 18%;
}

.sign-in {
  width: 32rem;
  text-align: center;
}

.order-page {
    display: flex;
    flex-direction: row;
    /* min-height: 100vh; */
    margin-top: 5%;
    margin-bottom: 5%;
    justify-content: space-around;
    background-color: green;
}

.filters {
  background-color: antiquewhite;
  padding: 2rem;
  border-radius: 1rem;
}

.orders {
  background-color: antiquewhite;
  padding: 2rem;
  border-radius: 1rem;
  width: 50rem;
}

.order {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  background-color: yellowgreen;
  margin: .5rem;
  padding: 1rem;
  border-radius: 1rem;
}

.order-button {
  font-size: .90rem;
}

.order-button:hover {
  cursor: pointer;
  color: red;
}

.order-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.orders heading {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around

}